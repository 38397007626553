import React from 'react'
import ReactStars from 'react-rating-stars-component'
import Button from '@material-ui/core/Button';

const GameReview = (props) => {

    return (
        <form>
            <label style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems:'center'}}>
                Wrtie A Review
                <ReactStars 
                count={5}
                onChange={props.handleStarChange}
                isHalf={true}
                size={24}
                activeColor="#ffd700"
                />
                <textarea value={props.review} onChange={props.handleChange} style={{width:'40vw', height: '20vh'}}/>
            </label>
            <Button onClick={props.handleSubmit} style={{width: '15%', backgroundColor: '#4c0066', color:'white'}} variant="contained" color="purprle">
                Submit
            </Button>
        </form>
    )
}

export default GameReview