import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

const useStyles = makeStyles((theme) => ({
  formControl: {
    color: 'white',
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  label: {
    //backgroundColor: 'white',
    color: 'white',
  },
  select: {

    color: 'white',

    '&:before': {
        borderColor: 'white',
    },
    '&:after': {
        borderColor: 'white',
        
    },
},
selected: {
    color:'purple'
},
icon: {
    fill: 'white',
},
}));

export default function MaterialGameFilter(props) {
    const classes = useStyles();

    
    return (
        <div>
        <FormControl className={classes.formControl}>
        <InputLabel className={classes.label} id="demo-simple-select-label">Platform</InputLabel>
        <Select
          className={classes.select}
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={props.value}
          onChange={props.handleChange}
          inputProps={{
            classes: {
                icon: classes.icon,
            },
        }}
        > 
        <MenuItem value="all">All</MenuItem>
        <MenuItem classes={{selected : classes.selected}} value="playstation">Playstation</MenuItem>
        <MenuItem classes={{selected : classes.selected}} value="xbox">XBox</MenuItem>
        <MenuItem classes={{selected : classes.selected}} value="nintendo">Nintendo</MenuItem>
        <MenuItem classes={{selected : classes.selected}} value="PC">PC</MenuItem>
        </Select>
      </FormControl>
      </div>
    )
}
