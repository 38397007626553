import React from 'react'
import UserReview from '../components/UserReview'
import MaterialLoaders from '../components/MaterialLoaders'

const UserReviews = () => {

    const [userReviews, setUserReviews] = React.useState([])
    const [isLoading, setIsLoading] = React.useState(false)

    React.useEffect(() => {
        setIsLoading(true)
        fetch(`https://us-central1-dggdb-56b82.cloudfunctions.net/api/reviews/read?game_id=${''}`)
        .then(res => res.json())
        .then(res => {
          const newReviews = userReviews
          for(let games in res) {
            for( let reviews in res[games]) {
                if(res[games][reviews].title){
                    newReviews.push({'text' : res[games][reviews].review, 'user': res[games][reviews].username, 'stars': res[games][reviews].starCount, 'time': res[games][reviews].time, 'title': res[games][reviews].title })
                }
            }
          }
          setUserReviews(newReviews)
          setIsLoading(false)
        })
    }, [userReviews])

    let loadingDiv = <div style={{width:'100%'}}>
        <MaterialLoaders />
    </div>

    if(!isLoading) {
        loadingDiv = <div style={{border:'2px solid black',backgroundColor: '#4c0066', width:'90%', position:'relative', display:'flex', flexDirection:'column', alignItems:'center', justifyContent:'center'}} >
        {userReviews.map((data) => {
        return <UserReview title={data.title} time={data.time} name={data.user} review={data.text} value={data.stars}/>
    })}
    </div>
    }

    return (
        <body>
            <div style={{display:'flex', flexDirection:'column', justifyContent:'center', alignItems:'center'}}>
            <h3 style={{color:'white'}}>User Reviews</h3>
            {loadingDiv}
            </div>
        </body>
        
    )
}

export default UserReviews 