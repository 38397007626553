import './App.css';
import React from 'react'
import { BrowserRouter as Router, Route } from "react-router-dom";
import Home from './containers/Home'
import GameDetail from './containers/GameDetail'
import UserProfile from './containers/UserProfile'
import firebase from './Auth/firebase'
import SearchBar from './components/SearchBar'
import MaterialLoaders from './components/MaterialLoaders'
import Header from './components/Header'
import UserReviews from './containers/UserReviews';

function App() {

  const [games, setApiGames] = React.useState([])
  const [currentUser, setCurrentUser] = React.useState('')
  const [value, setValue] = React.useState('all')
  const [order, setOrder] = React.useState('first_release_date')
  const [search, setSearch] = React.useState('')
  const [isLoading, setIsLoading] = React.useState(false)

  const handleChange = (event) => {
    setValue(event.target.value);
  }

  const orderHandleChange = (event) => {
    setOrder(event.target.value);
  }

  const searchHandleChange = (event) => {
    setSearch(event.target.value)
  }

  const checkUser = () => {
    firebase.auth().onAuthStateChanged(user => {
      if (user) {
        setCurrentUser(user)
      }
    });
  }

  React.useEffect(() => {

    const timeoutId = setTimeout(() => 
    {
    setIsLoading(true)
    fetch(`https://us-central1-dggdb-56b82.cloudfunctions.net/api/home?console_id=${value}&order=${order}&search=${search}`)
        .then(res => res.json())
        .catch(err=> console.log(err))
        .then(res => {
          let newGames = []
          if(res){
            for (const data in res) {
              res[data].key = res[data].id
              newGames.push(res[data])
            }
            setApiGames(newGames)
            setIsLoading(false)
          } else {
            setIsLoading(false)
            setApiGames('no games')
          }
        }); 
    checkUser()}
    , 1000);
    return () => clearTimeout(timeoutId);
  },[currentUser, order, value, search])

  if(!isLoading) {
   return (
    <Router>
    <div className="App">
      <Header currentUser={currentUser}/>
      <Route exact path="/" 
      render={() => {
      return <Home 
        search={search} 
        searchHandleChange={searchHandleChange} 
        value={value}
        order={order} 
        handleChange={handleChange} 
        orderHandleChange={orderHandleChange}
        games={games} 
        />
      }
    }
      ></Route>
      <Route path="/Detail/:id" 
      component={GameDetail}
      ></Route>
      <Route path="/user" 
      render={() => {return <UserProfile currentUser={currentUser}/>}}
      ></Route>
      <Route path="/users" 
      render={() => {return <UserReviews currentUser={currentUser}/>}}
      ></Route>
    </div>
    </Router>
  ) } else {
    return (
    <Router>
      <div className="App">
          <Header currentUser={currentUser} />
          <div>
          <SearchBar 
            search={search} 
            searchHandleChange={searchHandleChange} 
            value={value}
            order={order} 
            handleChange={handleChange} 
            orderHandleChange={orderHandleChange}
            games={games}
          />
          <div style={{height:'75vh', display:'flex', justifyContent:'center', alignItems:'center', flexDirection:'column'}}>
            <MaterialLoaders />
          </div>
        </div>
      </div>
    </Router>
    )}
}

export default App;
