import firebase from 'firebase/app'
import "firebase/auth";
import 'firebase/analytics'

const config = {
    apiKey: "AIzaSyBNkSHuMMCS3Wz82fg2awp2pzFU8H35wEQ",
    authDomain: "dggdb-56b82.firebaseapp.com",
    projectId: "dggdb-56b82",
    storageBucket: "dggdb-56b82.appspot.com",
    messagingSenderId: "480061623177",
    appId: "1:480061623177:web:7d95deb6525e192843ef58",
    measurementId: "G-H3S1G53KPH"
}

firebase.initializeApp(config)
firebase.analytics()

export const auth = firebase.auth

export default firebase;