import React from 'react'
import * as auth from './authentication'
import TextField from '@material-ui/core/TextField';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import axios from 'axios';

const CssTextField = withStyles({
    root: {
       '& label' :{
        color: 'purple',
       },
      '& label.Mui-focused': {
        color: 'purple',
      },
      '& .MuiInput-underline:before': {
        borderBottomColor: 'black',
      },
      '& .MuiInput-underline:after': {
        borderBottomColor: 'black',
      },
      '& .MuiOutlinedInput-root': {
        '& fieldset': {
          borderColor: 'purple',
        },
        '&:hover fieldset': {
          borderColor: 'purple',
        },
        '&.Mui-focused fieldset': {
          borderColor: 'purple',
        },
      },
      error: {}
    },
  })(TextField);

  const useStyles = makeStyles((theme) => ({
    root: {
      display: 'flex',
      flexWrap: 'wrap',
     
    },
    margin: {
      margin: theme.spacing(1),
      width: 200
    },
  }));



const SignIn = (props) => {
    const classes = useStyles();

    const [email, setUsername] = React.useState('');
    const [password, setPassword] = React.useState('');
    const [secondPassword, setSecondPassword] = React.useState('');
    const [firstName, setFname] = React.useState('');
    const [lastName, setLname] = React.useState('');
    const [signIn, setSignIn] = React.useState(true)
    const [passwordError, setPasswordError] = React.useState(false)
    const [emailError, setEmailError] = React.useState(false)
    const [text, setText] = React.useState('')
    const [emailText, setEmailText] = React.useState('')
    const [display, setDisplay] = React.useState('inline')


    const handleChange = (event, type) => {
        if(type === 'email') {
            setUsername(event.target.value)
        }  else if (type === 'password') {
            setPassword(event.target.value)
        } else if (type === 'secondPassword') {
          setSecondPassword(event.target.value)
          if(event.target.value !== password) {
            setPasswordError(true)
            setText('Password does not match')
          } else {
            setPasswordError(false)
            setText('Password match')
          }
        } else if (type === 'fname') {
            setFname(event.target.value)
        }  else if (type === 'lname') {
            setLname(event.target.value)
        } 
      }
    
      const handleSubmit = (event) => {
        console.log(display)
        if(signIn === true & display ==="inline") {
            event.preventDefault()
            auth.loginUser(email, password)
            .catch((err)=>{
              console.log(err)
              setEmailError(true)
              setEmailText(err.message)
            })
            .then((res)=> {
              if(res) {
                setUsername('')
                setPassword('')
                props.closeModal()
              }
            })
        }
        else if(signIn === true & display === 'none') {
          console.log('')
          auth.sendEmailReset(email)
        } else {
            event.preventDefault()
            axios.post('https://us-central1-dggdb-56b82.cloudfunctions.net/api/auth/signup', {email, password, firstName, lastName,})
            .catch((err) => {
              setEmailError(true)
              setEmailText(err.response.data)
            })
            .then((res) => {
              if(res) {
                 auth.loginUser(email, password)
                 .then(() => {
                  setText('')
                  setEmailText('')
                  setUsername('')
                  setPassword('')
                  setFname('')
                 setLname('')
                props.closeModal()
                 })  
            }
        })
      }
    }

    let signingIn = (
        <div  style={{display: display}}>
          <br></br>
        <CssTextField error={passwordError} helperText={text} className={classes.margin} id="custom-css-standard-input" value={secondPassword} onChange={(e) => handleChange(e, 'secondPassword')} type="password" placeholder="Confirm Password"/>
        <br></br>
        <br></br>
        <CssTextField className={classes.margin} id="custom-css-standard-input" value={firstName} onChange={(e) => handleChange(e, 'fname')} type="text" placeholder="First Name"/>
        <br></br>
        <br></br>
        <CssTextField className={classes.margin} id="custom-css-standard-input" value={lastName} onChange={(e) => handleChange(e, 'lname')} type="text" placeholder="Last Name"/>
        <br></br>
        </div>
    )
    
    let selector = <Button style={{width: '15%', backgroundColor: '#4c0066', color:'white'}} variant="contained" color="purprle">
    Login
  </Button>

   if(signIn === true) {
    signingIn = <div></div>
    selector = <Button style={{display: display, width: '20%', backgroundColor: '#4c0066', color:'white'}}variant="contained" >
    Sign Up
  </Button>
   } 
   
   return ( <form onSubmit={handleSubmit}>
    <label style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems:'center'}}>
      <h4>Sign In</h4>
      <CssTextField error={emailError} helperText={emailText} className={classes.margin} id="custom-css-standard-input" value={email} onChange={(e) => handleChange(e, 'email')} type="text" placeholder="Email"/>
      <br></br>
      <div style={{display: display}}>
      <CssTextField error={passwordError} helperText={text}className={classes.margin} id="custom-css-standard-input" value={password} onChange={(e) => handleChange(e, 'password')} type="password" placeholder="Password"/>
      <br></br>
      </div>
      {signingIn}
    </label>
    <Button onClick={handleSubmit } style={{ marginTop: '10px', marginBottom: '20px', width:'20%', backgroundColor: '#4c0066', color:'white'}} variant="contained" color="purprle">
    Submit
  </Button  >
    <div onClick={() => {setSignIn(!signIn)}}>
        {selector}
    </div>
    <Button onClick={()=>{setDisplay('none')}} style={
      {
        marginTop: '20px',
        marginBottom: '0px'
      }}
       >
        Forget Password?
      </Button>
    </form>
    
    )
}

export default SignIn