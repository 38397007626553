import React from 'react'
import MaterialGameFilter from './MaterialGameFilter'
import MaterialGameOrder from './MaterialGameOrder'
import MaterialSearch from './MaterialSearch'

const SearchBar = (props) => {
   return (
   <div style={{display: 'flex', flexDirection:'row', width: '100%', justifyContent:'space-evenly', alignItems:'center', margin: '10px'}}>
    <MaterialGameFilter value={props.value} handleChange={props.handleChange}/>
    <MaterialSearch search={props.search} handleChange={props.searchHandleChange} />
    <MaterialGameOrder order={props.order} handleChange={props.orderHandleChange}/>
  </div>
   )
}

export default SearchBar