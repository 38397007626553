import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

const useStyles = makeStyles((theme) => ({
  formControl: {
    color: 'white',
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  label: {
    //backgroundColor: 'white',
    color: 'white',
  },
  select: {
    color: 'white',
    '&:before': {
        borderColor: 'white',
    },
    '&:after': {
        borderColor: 'white',
    },
},
selected: {
    color:'purple'
},
icon: {
    fill: 'white',
},
}));

export default function MaterialGameFilter(props) {
    const classes = useStyles();

    
    return (
        <div>
        <FormControl className={classes.formControl}>
        <InputLabel className={classes.label} id="demo-simple-select-label">Order</InputLabel>
        <Select
          className={classes.select}
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={props.order}
          onChange={props.handleChange}
          inputProps={{
            classes: {
                icon: classes.icon,
            },
        }}
        > 
        <MenuItem classes={{selected : classes.selected}} value="first_release_date">Popular Releases</MenuItem>
        <MenuItem classes={{selected : classes.selected}} value="all_new">All New Releases</MenuItem>
        <MenuItem classes={{selected : classes.selected}} value="aggregated_rating_count">Most Reviewed</MenuItem>
        <MenuItem classes={{selected : classes.selected}} value="rating">Rating</MenuItem>
        <MenuItem classes={{selected : classes.selected}} value="upcoming">Upcoming</MenuItem>
        </Select>
      </FormControl>
      </div>
    )
}
