import '../App.css';
import React from 'react'
import GameCard from '../components/GameCard'
import MaterialLoaders from '../components/MaterialLoaders'

const UserProfile = (props) => {

  const [userWants, setUserWants] = React.useState([])
  const [userFavs, setUserFavs] = React.useState([])
  const [isLoading, setIsLoading] = React.useState('')

 React.useEffect(() => {

    setIsLoading(true)
    console.log('fetching')
    fetch(`https://us-central1-dggdb-56b82.cloudfunctions.net/api/favorites/games/read?user=${props.currentUser.uid}`)
    .catch(err => {
      console.log(err)
      setUserWants([])
      setIsLoading(false)
    })
    .then(res => res.json())
    .then(res => {
    console.log(res)
    let newGames = []
     for (const data in res) {
       res[data].key = res[data].id
       newGames.push(res[data])
   }
    setUserFavs(newGames)
    setIsLoading(false)
  });
  
  fetch(`https://us-central1-dggdb-56b82.cloudfunctions.net/api/wants/games/read?user=${props.currentUser.uid}`)
    .catch(err => {
      console.log(err)
      setUserWants([])
      setIsLoading(false)
    })
    .then(res => res.json())
    .then(res => {
    setIsLoading(true)
    console.log(res)
    let newGames = []
     for (const data in res) {
       res[data].key = res[data].id
       newGames.push(res[data])
   }
   setUserWants(newGames)
    setIsLoading(false)
  }) 
 },[props.currentUser])

  let favContentDiv = (
    <div>
      <div>
        <h3 style={{color:'white'}}>Favorites</h3>
        </div>  
    <div className="game-list">
            {
            userFavs.map((data) => {
              return (
              <GameCard
              user={props.user}
              name={data.name}
              realeaseDate={data.first_release_date}
              rating={data.rating ? data.rating.toFixed(2) : 0}
              key={data.key}
              imageSrc={data.imageUrl ? data.imageUrl : "#"}
              summary={data.summary}
              platforms={data.platforms}
              id={data.id}
              involved_companies={data.involved_companies}
              />
            )})}
        </div>
      <div style={{ marginTop: '50px'}}>
      <h3 style={{color:'white',}}>Want to Play</h3>
    </div>  
    <div className="game-list">
    {
    userWants.map((data) => {
      return (
      <GameCard
      user={props.user}
      name={data.name}
      realeaseDate={data.first_release_date}
      rating={data.rating ? data.rating.toFixed(2) : 0}
      key={data.key}
      imageSrc={data.imageUrl ? data.imageUrl : "#"}
      summary={data.summary}
      platforms={data.platforms}
      id={data.id}
      involved_companies={data.involved_companies}
      />
    )})}
</div>
</div>
  )

  if(isLoading) {
    favContentDiv = (
    <div style={{height: '30vh', display:'flex', flexDirection: 'row', alignItems: 'center'}}>
      <MaterialLoaders />
    </div>
  )
}

  return (
      <body>
          
          {favContentDiv}
      </body>
  );
}

export default UserProfile;