import '../App.css';
import React from 'react'
import GameCard from '../components/GameCard'
import SearchBar from '../components/SearchBar'

const Home = (props) => {

  let content = <div style={{height: '60vh', display:'flex', flexDirection: 'column', alignItems:'center', justifyContent: 'center'}}><h3 style={{color: 'white'}}>No Game Found</h3></div>
  console.log()
  if (props.games !== 'no games') {
    content = <div className="game-list">
    {
    props.games.map((data) => {
      return (
      <GameCard
      user={props.user}
      name={data.name}
      realeaseDate={data.first_release_date}
      rating={data.rating ? data.rating.toFixed(2) : 0}
      key={data.key}
      imageSrc={data.imageUrl ? data.imageUrl : "#"}
      summary={data.summary}
      platforms={data.platforms}
      id={data.id}
      involved_companies={data.involved_companies}
      />
    )})}
</div>
  } else {
    content = <div style={{height: '60vh', display:'flex', flexDirection: 'column', alignItems:'center', justifyContent: 'center'}}><h3 style={{color: 'white'}}>No Game Found</h3></div>
  }

  return (
      <body>
        <div>
        <SearchBar 
          value={props.value}
          order={props.order}
          search={props.search}
          handleChange={props.handleChange}
          searchHandleChange={props.searchHandleChange}
          orderHandleChange={props.orderHandleChange}
        />
        </div>
        <div>
        {content}
        </div>
      </body>
  );
}

export default Home;