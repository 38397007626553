import {auth} from './firebase';

export const sendEmailReset = (emailAddress) => {
  auth().sendPasswordResetEmail(emailAddress).then(function() {
  // Email sent.
  console.log('Sent')
}).catch(function(error) {
  console.log(error)
});
}
export const loginUser = (email, password) => {
  return auth().signInWithEmailAndPassword(email, password);
}