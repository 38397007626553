import React from 'react'
import { FaStar, FaGamepad } from 'react-icons/fa';
import Modal from './Modal'
import SignIn from '../Auth/SignIn'

const GameSummary = (props) => {

    const [isFavoriteBlack, setIsFavoriteBlack] = React.useState(true)
    const [isWantsBlack, setIsWantsBlack] = React.useState(true)
    const [wantColor, setWantColor] = React.useState('black')
    const [favColor, setFavColor] = React.useState('black')
    const [status, setStatus] = React.useState(false)

    const onFavChangeHandler = (isBlackCheck) => {
      if(props.currentUser === undefined) {
        setStatus(true)
      }
      else {
        if(isFavoriteBlack) {
          setFavColor('white')
          fetch(`https://us-central1-dggdb-56b82.cloudfunctions.net/api/favorites/write?user=${props.currentUser}&game_id=${props.gameId}&favorite=true`)
          .then(res => res.json())
          .then(res => {
            console.log(res)
          })
        }
        if(!isFavoriteBlack) {
          setFavColor('black')
          fetch(`https://us-central1-dggdb-56b82.cloudfunctions.net/api/favorites/write?user=${props.currentUser}&game_id=${props.gameId}&favorite=false`)
          .then(res => res.json())
          .then(res => {
            console.log(res)
          })
        }
      }
    }
    const onWantsChangeHandler = (isBlackCheck) => {
      if(props.currentUser === undefined) {
        setStatus(true)
      }
      else {
        if(isWantsBlack) {
          setWantColor('white')
          fetch(`https://us-central1-dggdb-56b82.cloudfunctions.net/api/wants/write?user=${props.currentUser}&game_id=${props.gameId}&favorite=true`)
          .then(res => res.json())
          .then(res => {
            console.log(res)
          })
        }
        if(!isWantsBlack) {
          setWantColor('black')
          fetch(`https://us-central1-dggdb-56b82.cloudfunctions.net/api/wants/write?user=${props.currentUser}&game_id=${props.gameId}&favorite=false`)
          .then(res => res.json())
          .then(res => {
            console.log(res)
          })
        }
      }
    }

    React.useEffect(() => {
      fetch(`https://us-central1-dggdb-56b82.cloudfunctions.net/api/favorites/read?user=${props.currentUser}&game_id=${props.gameId}`)
      .then(res => res.json())
      .then(res => {
          console.log((res))
          if(res !== null) {
          if (res.favorite) {
            setIsFavoriteBlack(false)
            setFavColor('white')
          } else {
            setIsFavoriteBlack(true)
            setFavColor('black')
          }
        }
      })

      fetch(`https://us-central1-dggdb-56b82.cloudfunctions.net/api/wants/read?user=${props.currentUser}&game_id=${props.gameId}`)
      .then(res => res.json())
      .then(res => {
          console.log((res))
          if(res !== null) {
          if (res.favorite) {
            setIsWantsBlack(false)
            setWantColor('white')
          } else {
            setIsWantsBlack(true)
            setWantColor('black')
          }
        }
      })

    }, [props.currentUser, props.gameId])

    return (
    
    <div style={{position: 'relative', display:'flex', flexDirection:'column', justifyContent:'flex-start'}}>
        { status && (
        <Modal closeModal={() => setStatus(false)}> 
          <SignIn closeModal={() => setStatus(false)}/>
        </Modal>
        )}
        <div style={{ display:'flex', flexDirection: 'row', justifyContent: 'space-evenly', alignItems: 'center' }}>
          <div >
            <h4 style={{color:'white', margin:'10px'}}> IGDB Rating : </h4>
            <h3 style={{color:'white', margin:'5px'}}>{props.rating}</h3>
          </div>
          <div>
            <h4 style={{color:'white',  margin:'10px'}}>Release Date :</h4>
            <h3 style={{color:'white', margin:'5px'}}>{props.realeaseDate}</h3>
          </div>
        </div>
        
        <hr style={{height:'2px', width: '100%', borderWidth:'0',color:'gray',backgroundColor:'gray'}} />
      
        <div style={{ display:'flex', flexDirection: 'row', justifyContent: 'space-evenly', alignItems: 'center' }}>
        <div style={{display:'flex', flexDirection: 'column'}}>
          <h5 style={{color:'white',  margin:'0px'}}>Favorite</h5>
        <div><FaStar size={24} style={{color: favColor}} onClick={()=> {
          isFavoriteBlack ? setIsFavoriteBlack(false) : setIsFavoriteBlack(true)
          onFavChangeHandler(isFavoriteBlack)
          }}/></div>
          </div>
          <div style={{display:'flex', flexDirection: 'column'}}>
          <h5 style={{color:'white',  margin:'0px'}}>Want To Play</h5>
        <div><FaGamepad size={24} style={{color: wantColor}} onClick={()=> {
          isWantsBlack ? setIsWantsBlack(false) : setIsWantsBlack(true)
          onWantsChangeHandler(isWantsBlack)
          }}/></div>
        </div>
        </div>
        <hr style={{height:'2px', width: '100%', borderWidth:'0',color:'gray',backgroundColor:'gray'}} />
        
        <div style={{display: 'flex', justifyContent: 'center'}}>
        <h3 style={{borderBottom:'2px solid gray',color:'white', margin:'5px'}}>Game Summary</h3>
        </div>
        <article  style={{display: 'block',  textAlign:'left'}}>
          <h4 style = {{color:'white', margin:'5px'}}>
              {props.summary}
          </h4>
        </article>
    </div>
    )
}

export default GameSummary