import React from 'react'
import ReactStars from 'react-rating-stars-component'

const UserReview = (props) => {
  console.log(props.time)
  let date = new Date(parseInt(props.time))
  return (
        <div style={{height:'100%', width: '100%', borderBottom: '2px solid gray',borderColor:'gray'}}>
            {props.title ?<h5 style={{color:'white',  margin:'10px',  textAlign: 'center'}}>{props.title}</h5> : ''}
            <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'start'}}>
            <h5 style={{color:'white',  margin:'10px',  textAlign: 'left'}}>{props.name}</h5>
            
            {props.time ? <h5 style={{color:'white',  margin:'10px',  textAlign: 'right', position:'absolute', right:'5px'}}>{date.toDateString()}</h5> : ''}
            <ReactStars 
            count={5}
            edit={false}
            isHalf={true}
            value={props.value}
            size={15}
            activeColor="#ffd700"
            />
            
            </div>
            <article>
              <p style={{color: 'white', textAlign: 'left'}}>
                {props.review}
              </p>
            </article>
          </div>
    )

}

export default UserReview