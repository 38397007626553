import React from 'react'
import { Link } from "react-router-dom";
import SignIn from '../Auth/SignIn'
import firebase from '../Auth/firebase'
import Modal from './Modal'

const Header = (props) => {

    const [status, setStatus] = React.useState(false)

    let needSignIn = (
        <div className="header-title">
        <Link to="/" style={{textDecoration: 'none', color:'white'}}><p >Good Games Guide</p></Link>
        <button 
          onClick={() => setStatus(true)} 
          className={'header-link'}>
                Sign In
          </button>
          </div>
        )
    
      if(props.currentUser === '') {
        
      } else {
      needSignIn = (
      <div className="header-title">
        <button onClick={()=>{
        firebase.auth().signOut().then(()=>window.location.reload(false));
        }} className={'header-link-left'}>Logout</button>
        <Link to="/" style={{textDecoration: 'none', color:'white'}}><p >Good Games Guide</p></Link>
        <Link 
          to="/user" 
          className={'header-link-left'}>
              {props.currentUser.displayName}
          </Link>
        </div>
      )
      }


    return (
            <div>
             {status && (
                <Modal closeModal={() => setStatus(false)}> 
                <SignIn closeModal={() => setStatus(false)}/>
                </Modal>
                )}
            <header className="App-header">
                {needSignIn}
            <div className="header-pages">
                <ul>
                    <li>
                        <Link to="/">Games</Link>
                    </li>
                    <li>
                        <Link to='/users'>Feed</Link>
                    </li>
                    {/* <li>
                        News
                    </li> */}
                </ul>
            </div>
            </header>
            </div>
    )

}

export default Header