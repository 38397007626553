import React from 'react';
import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button';

const Modal = (props) => {
  const { closeModal } = props;
  const useStyles = makeStyles((theme) => ({
    root: {
      '& > *': {
        margin: theme.spacing(1),
      },
    },
  }));

  const classes = useStyles();

  return (
    <div className="overlay">
      <div className="content">
      <div className={classes.root}>
    <Button style={
      {
        position: 'absolute',
        top: '0.3rem',
        right: '0.5rem',
        color:'black',
        fontWeight: '700'
      }}
       onClick={closeModal} 
       >
        X
      </Button>
      </div>
        {props.children}
      </div>
    </div>
  );
};


export default Modal;