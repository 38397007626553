import React from 'react';
import { makeStyles, withStyles } from "@material-ui/core/styles";
import LinearProgress from "@material-ui/core/LinearProgress";

const ColorLinearProgress = withStyles({
  root: {
      width: '100%'
  },
  colorPrimary: {
    backgroundColor: 'rgb(12, 10, 14)',
  },
  barColorPrimary: {
    backgroundColor: 'purple',
  },
})(LinearProgress);

const SecondColorLinearProgress = withStyles({
    root: {
        width: '100%'
    },
    colorPrimary: {
      backgroundColor: 'rgb(12, 10, 14)',
    },
    barColorPrimary: {
      backgroundColor: 'white',
    },
  })(LinearProgress);


const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection:'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    flexGrow: 1,
  },
  margin: {
    margin: theme.spacing(1),
  },
}));

export default function CustomizedProgressBars() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <SecondColorLinearProgress className={classes.margin} />
      <ColorLinearProgress className={classes.margin} />
    </div>
  );
}