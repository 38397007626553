import React from 'react';
import TextField from '@material-ui/core/TextField';
import { withStyles, makeStyles } from '@material-ui/core/styles';


const CssTextField = withStyles({
    root: {
       '& label' :{
        color: 'white',
       },
      '& label.Mui-focused': {
        color: 'white',
      },
      '& .MuiInput-underline:before': {
        borderBottomColor: 'white',
      },
      '& .MuiInput-underline:after': {
        borderBottomColor: 'white',
      },
      '& .MuiOutlinedInput-root': {
        '& fieldset': {
          borderColor: 'white',
        },
        '&:hover fieldset': {
          borderColor: 'white',
        },
        '&.Mui-focused fieldset': {
          borderColor: 'white',
        },
      },
    },
  })(TextField);

  const useStyles = makeStyles((theme) => ({
    root: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    margin: {
      margin: theme.spacing(1),
    },
  }));

  

export default function FormPropsTextFields(props) {
  const classes = useStyles();
  
  return (
    <form className={classes.root} noValidate autoComplete="off">
      <div>
      <CssTextField onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }} onChange={props.handleChange} value={props.search} InputProps={{style:{color:'white'}}} className={classes.margin} id="custom-css-standard-input" label="Search" />
      </div>
    </form>
  );
}