import React from 'react'
import {Link} from 'react-router-dom'

const GameCard = (props) => {
  
  return (
    <Link 
    to={{
      pathname: `/Detail/${props.name}`,
      state: {props : props}
    }} 
    className="container" 
    onClick={props.onClick}
    params={props}
    >
      <div className="info">
      <p>{props.name}</p>
      <p style={{color: '#fff', fontWeight: '900',}}>Realease Date:</p>
      <p>{props.realeaseDate}</p>
      <p style={{color: '#fff', fontWeight: '900',}}>Rating: </p>
      <p>{props.rating}</p>
      </div>
      <img src={`${props.imageSrc}`} alt={`${props.name}`}></img> 
    </Link> 
  )
}

export default GameCard